import React, {Component} from "react";

export default class Navbar extends Component {

    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                        <button className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarToggler"
                                aria-controls="navbarToggler"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarToggler">
                            <a className="navbar-brand" href="#">
                                <img src="logo.png" height="30" class="d-inline-block align-top" alt="" /> API Méteo
                            </a>
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link" href="./">Accueil <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="./localisation">Ma localisation</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="./meteo">Ma météo</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}