import React, {Component} from "react";

export default class Footer extends Component {

    render() {
        return (
            <div className="container-fluid">
                <div className="col-md-8 offset-2 mt-5 text-center text-white">
                    Copyright API Méteo Anthony ARJONA.
                </div>
            </div>
        );
    }
}